<template>
  <div>
    <div class="row page-title">
      <label>Request {{ ClientCode }}</label>
    </div>
    <div class="page-details">
      <client-overview :ClientRequest="ClientRequest" :ID="id"></client-overview>
    </div>
    <b-form-group class="text-right footer-buttons" v-if="!Error">
      <button class="btn custom-button btn-link" @click="Cancel()">
        Cancel
      </button>
      <button
        class="btn custom-button btn-outline-primary"
        @click="ShowReject()"
      >
        Reject
      </button>
      <button class="btn custom-button btn-primary" @click="Complete()">
        Complete
      </button>
    </b-form-group>
    <b-modal
      v-model="ShowRejectModal"
      :no-close-on-backdrop="true"
      scrollable
      title="Add Product"
    >
      <template v-slot:modal-header="{ close }">
        <h5 class="modal-title">{{ ModalTitle }}</h5>
        <button class="close" @click="CancelReject()"></button>
      </template>
      <div
        class="d-block row col-lg-6 col-md-8 offset-lg-2 offset-lg-3 primary-content"
      >
        <b-row>
          <b-col>
            <label>Rejection Comments</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-textarea v-model="Comments" rows="5"> </b-textarea>
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <div class="text-right">
          <button class="btn btn-link text-uppercase" @click="CancelAdd()">
            Cancel
          </button>
          <button
            :disabled="$v.$invalid"
            class="text-uppercase btn custom-button btn-primary"
            @click="Reject()"
          >
            Reject
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { requiredIf } from "vuelidate/lib/validators";
import store from "@/store";
export default {
  name: "CompleteRequest",
  validations: {
    Comments: {
      required: requiredIf(function () {
        return this.ShowRejectModal == true;
      }),
    },
  },
  data() {
    return {
      ClientRequest: {},
      Error: false,
      ShowRejectModal: false,
      Comments: "",
    };
  },
  props: ["id"],
  computed: {
    ClientCode() {
      return this.ClientRequest ? this.ClientRequest.RequestedClientCode : "";
    },
    ModalTitle() {
      return `Reject Request ${this.ClientCode}`;
    },
    canCompleteClientRequest() {
      return this.$store.getters.canCompleteClientRequest;
    },
  },
  mounted() {
    if (!this.canCompleteClientRequest) {
      this.$router.push({
        name: "error",
        params: { message: "UnAuthorized" },
      });
    } else {
      this.$store
        .dispatch("client/GetClientRequestWithTaskDetails", parseInt(this.id))
        .then((res) => {
          this.ClientRequest = res.data;
        })
        .catch((err) => {
          if (err.response && err.response.status == 403) {
            this.$router.push({
              name: "error",
              params: {
                message: "UnAuthorized"
              },
            });
          }
          else{
            this.Error = true;
            alert(err);
          }
        });
    }
  },
  methods: {
    GetData() {
      return {
        ID: parseInt(this.id),
        Comments: "",
        ActiveTaskID: this.ClientRequest.ActiveTaskID,
        ProjectID: this.ClientRequest.OmniflowProjectID,
        ProcessID: this.ClientRequest.OmniflowProcessID,
      };
    },
    ShowReject() {
      this.ShowRejectModal = true;
    },
    CancelReject() {
      this.ShowRejectModal = false;
    },
    Reject() {
      this.ShowRejectModal = false;
      store.dispatch(
        "progress/setSuccessMessage",
        `Request ${this.ClientCode} has been rejected`
      );
    },
    Complete() {
      this.ShowModal = false;
      this.$store
        .dispatch("client/CompleteRequest", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `The request ${this.ClientCode} has been completed`
          );
          this.$router.push("/ClientProduct");
        })
        .catch(() => {
          store.dispatch(
            "progress/setErrorMessage",
            `Error while completing request ${this.ClientCode}`
          );
        });
    },
    Cancel(){
        this.$router.push("/ClientProduct");
    }
  },
};
</script>
<style scoped>
@media screen and (min-width: 1200px) {
  .page-details {
    padding: 1rem 3rem 0px !important;
  }
}

.row.page-title {
  height: 55px;
  background-color: #eef3f5 !important;
}
.row.page-title > label {
  padding: 14px 0px 14px 55px !important;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
  text-transform: none !important;
}
</style>